import { Langs } from './lang';
import { User, UserTokens } from './user';

export enum AccountTypes {
  Core = 'core',
  Corporate = 'corporate',
}

export enum AuthActions {
  login = 'login',
  signup = 'signup',
}

export enum OAuthTypes {
  Google = 'google',
  Apple = 'apple',
}

export enum OtherAuthTypes {
  Email = 'email',
}

/** @todo: merge enums */
export enum AuthTypes {
  Google = 'google',
  Apple = 'apple',
  Email = 'email',
}

export enum SSOTypes {
  Corporate = 'corporate',
  Majelan = 'majelan',
  Apple = 'apple',
  Google = 'google',
  Kedge = 'kedge',
  Link = 'link',
  SSO = 'sso',
}

export type QueryState = { sso: SSOTypes; type: AuthActions; url_from?: string };

export type LoginData = {
  id: string;
  name: string;
  oryProject: string;
  provider: string;
  loginHint: boolean;
  prompt: 'none' | 'consent' | 'login' | 'registration' | 'create' | 'select_account';
};

export type AccountDatas = {
  tokenID?: string;
  fbAccessToken?: string;
  token?: string;
  email?: string;
  password?: string;
  code?: string;
  type?: string;
  lang?: Langs;
};

export interface LoginRequest {
  accountDatas: AccountDatas;
  accountType: SSOTypes;
  gcu?: boolean;
  newsletter?: boolean;
  platform?: 'web';
  lang?: Langs;
  partnership?: boolean;
}

export interface LoginResponse extends User, UserTokens {
  email: string;
}

export interface ResolveResponse {
  type: 'corporate' | 'majelan' | null;
  domain?: LoginData;
}
