import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { getMediasProgram } from '@/modules/medias/domain/resources';
import { useNotifications } from '@/modules/notifications/hooks/useNotifications';
import { getProgram } from '@/modules/programs/domain/resources';
import { getRoles } from '@/modules/user/domain/resources/getRoles';
import { Media, Program, Role } from '@/types';
import { homeRoute } from '@/utils/routes';

export const useProgram = (
  programUUID: string,
  isPro?: boolean
): {
  program: Program;
  isLoadingProgram: boolean;
  medias: Media[];
  roles: Role[];
  programError?: unknown;
} => {
  const queryClient = useQueryClient();
  const { showNotificationError } = useNotifications();
  const router = useRouter();

  const {
    data,
    isFetching,
    error: programError,
  } = useQuery(['program', programUUID], () => getProgram(programUUID, isPro), {
    enabled: !!programUUID,
    retry: 0,
    onError: (err: HTTPError) => {
      const errorMessage =
        err?.response?.status === 404 ? 'program.errors.notFound' : 'program.errors.generic';
      showNotificationError(errorMessage);
      setTimeout(() => router.push(homeRoute), 2000);
    },
  });

  const { data: medias } = useQuery(
    ['program', data?.mediaKeyId, 'medias'],
    () => getMediasProgram(data.mediaKeyId),
    {
      enabled: !!data?.mediaKeyId,
      staleTime: Infinity,
    }
  );

  const { data: roles }: { data?: Role[] } = useQuery(
    ['roles', programUUID],
    () => getRoles(programUUID),
    {
      enabled: !!programUUID,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    queryClient.setQueryData(['current-medias'], medias || []);
  }, [medias, queryClient]);

  return {
    program: data,
    isLoadingProgram: isFetching,
    roles,
    medias,
    programError,
  };
};
