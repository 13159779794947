import { chunk, fromPairs, isEmpty } from 'lodash';

import { QueryState } from '@/types';

export const getQueryState = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const location = window.location.hash.substring(1) || window.location.search;

  const query = Object.fromEntries(new URLSearchParams(location));

  if (isEmpty(query)) return { query: null, state: null };

  const queryWithoutstate = Object.fromEntries(
    Object.entries(query).filter(([key]) => key !== 'state')
  );

  return {
    query: queryWithoutstate,
    state: (query?.state
      ? fromPairs(chunk((query.state as string).split(','), 2))
      : null) as QueryState,
  };
};
