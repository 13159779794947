import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Notification, NotificationTypes } from '@/types/notifications';

import {
  NotificationsContext,
  NotificationsContextDispatch,
  NotificationsContextState,
  NotificationsDispatchContext,
} from './NotificationsProvider.context';

interface NotificationsContextProps {
  children?: React.ReactNode;
}

export const NotificationsProvider = ({ children }: NotificationsContextProps) => {
  const { formatMessage } = useIntl();
  const [notification, setNotification] = useState<Notification>(null);

  const setNotificationWithType = ({
    title,
    message,
    titleParams,
    messageParams,
    type,
  }: Notification) =>
    setNotification({
      title: title && formatMessage({ id: title }, titleParams),
      message: message && formatMessage({ id: message }, messageParams),
      type,
    });

  const showNotificationError = (params: string | Notification = {}) => {
    const args = typeof params === 'string' ? { message: params } : params;

    setNotificationWithType({
      title: 'notification.error',
      type: NotificationTypes.Error,
      ...args,
    });
  };

  const showNotificationInfo = (params: string | Notification = {}) => {
    const args = typeof params === 'string' ? { message: params } : params;

    setNotificationWithType({
      type: NotificationTypes.Information,
      ...args,
    });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  const contextValue: NotificationsContextState = {
    notification,
  };

  const dispatchContextValue: NotificationsContextDispatch = {
    showNotificationError,
    showNotificationInfo,
    hideNotification,
    notification,
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      <NotificationsDispatchContext.Provider value={dispatchContextValue}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsContext.Provider>
  );
};
