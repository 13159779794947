import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { postSignin } from '@/modules/auth/domain/resources/core/postSignin';
import { loginCorporate } from '@/modules/auth/domain/resources/corporate/login';
import { useNotifications } from '@/modules/notifications/hooks/useNotifications';
import { LoginRequest, SSOTypes } from '@/types';
import { HTTPApiError } from '@/utils/HTTPApiError';
import { setUserSession } from '@/utils/browserStorage';
import { authEmailRoute } from '@/utils/routes';

export const useLogin = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showNotificationError, showNotificationInfo } = useNotifications();

  const { mutateAsync: login } = useMutation((formData: LoginRequest) => postSignin(formData), {
    onMutate: () => {
      setError(null);
      setLoading(true);
    },
    onSuccess: async (data, variables) => {
      await setUserSession(data);
      queryClient.setQueryData(['user'], data);

      if (variables.accountType !== SSOTypes.Corporate && data.signUpType === SSOTypes.Corporate) {
        const email = data.email;
        await loginCorporate({ email });
      }
      setLoading(false);

      if (!data) {
        showNotificationError({
          title: 'common.notifications.error.login.title',
          message: 'common.notifications.error.login.description',
        });
        return;
      }

      if (variables.accountType === SSOTypes.Link) {
        router.push(authEmailRoute(variables.accountDatas.email));
      } else {
        // Don't show welcome notification if user is not yet onboarded
        if (data?.categories?.length < 2) return;

        setTimeout(
          () =>
            showNotificationInfo({
              message: `common.notifications.welcome.signIn.${
                data.firstName ? 'withFirstName' : 'withoutFirstName'
              }`,
              messageParams: { firstname: data.firstName },
            }),
          1000
        );
      }
    },
    onError: async (err: HTTPApiError) => {
      setLoading(false);
      const errorMessage = await err.response.json();
      setError(errorMessage.error);
    },
  });

  return { login, isLoadingLogin: loading, loginError: error };
};
