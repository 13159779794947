import { memo } from 'react';
import { useIntl } from 'react-intl';
import { usePlayerSound } from '@/modules/player/providers/dispatch/usePlayerSound';
import { usePlayer } from '@/modules/player/providers/PlayerProvider';
import { Icon } from '@/components/Icon';

const SoundWrapper = () => {
  const { sound } = usePlayer();
  const handleSound = usePlayerSound();

  const { formatMessage } = useIntl();

  const toggleMutted = () => {
    if (sound === 1) {
      handleSound(0);
    } else {
      handleSound(1);
    }
  };

  return (
    <button
      className="sound-button"
      onClick={toggleMutted}
      aria-label={formatMessage({ id: 'player.accessiblity.sound' })}
    >
      <Icon name={sound === 1 ? 'sound-on' : 'sound-off'} className="text-2xl" />
    </button>
  );
};

export const Sound = memo(SoundWrapper);
