import { memo, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import { SideBarRight } from '@/components/SideBarRight';
import { useMediasInProgress } from '@/hooks/useMediasInProgress';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useTrackPlayer } from '@/modules/analytic/hooks/useTrackPlayer';
import { useMediasPrograms } from '@/modules/medias/hooks/useMediasPrograms';
import { BackForward } from '@/modules/player/presentation/atoms/BackForward';
import { Next } from '@/modules/player/presentation/atoms/Next';
import { PlayPause } from '@/modules/player/presentation/atoms/PlayPause';
import { QueueListButton } from '@/modules/player/presentation/atoms/QueueListButton';
import { Snooze } from '@/modules/player/presentation/atoms/Snooze';
import { Sound } from '@/modules/player/presentation/atoms/Sound';
import { Speed } from '@/modules/player/presentation/atoms/Speed';
import { MediaInfo } from '@/modules/player/presentation/molecules/MediaInfo';
import { ProgressBar } from '@/modules/player/presentation/molecules/ProgressBar';
import { QueueList } from '@/modules/player/presentation/molecules/QueueList';
import { playerRef, usePlayer } from '@/modules/player/providers/PlayerProvider';
import { usePlayerDuration } from '@/modules/player/providers/dispatch/usePlayerDuration';
import { usePlayerEnded } from '@/modules/player/providers/dispatch/usePlayerEnded';
import { usePlayerError } from '@/modules/player/providers/dispatch/usePlayerError';
import { usePlayerProgress } from '@/modules/player/providers/dispatch/usePlayerProgress';
import { usePlayerQueueListDisplay } from '@/modules/player/providers/dispatch/usePlayerQueueListDisplay';
import { usePlayerStart } from '@/modules/player/providers/dispatch/usePlayerStart';
import { usePlayerStartMedia } from '@/modules/player/providers/dispatch/usePlayerStartMedia';
import { Breakpoints } from '@/types';
import { retrieveAuthSession } from '@/utils/browserStorage';

import { PlayerMobile } from './PlayerMobile';

/* - 56 because header has h-14 (see: .header) */
const HEADER_HEIGHT = 56;

const PlayerWrapper = () => {
  const sheetRef = useRef<BottomSheetRef>();
  const [openMobilePlayer, setOpenMobilePlayer] = useState(false);
  const [url, setUrl] = useState('');
  const { trackPlayerView } = useTrackPlayer();
  const { screenWidth } = useWindowSize();
  const { startMedia } = usePlayerStartMedia();
  const handleStart = usePlayerStart();
  const handleEnded = usePlayerEnded();
  const handleProgress = usePlayerProgress();
  const handleDuration = usePlayerDuration();
  const handleError = usePlayerError();
  const handleQueueListDisplay = usePlayerQueueListDisplay();
  const { mediasInProgress } = useMediasInProgress();
  const { mediasProgramsData: mediasProgramsInProgress, isLoading: isLoadingMediasInProgress } =
    useMediasPrograms(mediasInProgress);
  const {
    mediaToPlay,
    playbackURL,
    playing,
    lastTimeInMedia,
    currentMedia,
    speed,
    queueListDisplayed,
    sound,
  } = usePlayer();

  const session = retrieveAuthSession();

  useEffect(() => {
    if (screenWidth > Breakpoints.Small) {
      return setOpenMobilePlayer(false);
    }
  }, [screenWidth, setOpenMobilePlayer]);

  useEffect(() => {
    queueListDisplayed
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [queueListDisplayed]);

  useEffect(() => {
    if (!currentMedia) return;

    trackPlayerView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @todo: This hacks the permanentPlaybackURL issue pro external content
   */
  useEffect(() => {
    if (!playbackURL) return;

    if (!playbackURL.includes(process.env.NEXT_PUBLIC_MAJELAN_API)) {
      setUrl(playbackURL);
      return;
    }

    const options = {
      headers: {
        Authorization: `Bearer ${session?.accessToken}`,
      },
    };

    (async () => {
      try {
        const response = await (await fetch(playbackURL, options)).json();
        setUrl(response?.playbackURL || playbackURL);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [playbackURL, session?.accessToken]);

  /**
   * Start playing the first media in progress if there is no media to play
   */
  useEffect(() => {
    if (
      isLoadingMediasInProgress ||
      mediaToPlay?.media?.UUID ||
      mediaToPlay ||
      !mediasProgramsInProgress?.length
    )
      return;
    const { media, history } = mediasProgramsInProgress[0];

    startMedia({
      media,
      lastTimeInMedia: history?.lastTimeInMedia || 0,
      isFromQueueList: false,
      autoPlay: false,
    });
  }, [
    isLoadingMediasInProgress,
    mediasProgramsInProgress,
    mediaToPlay,
    mediaToPlay?.media?.UUID,
    startMedia,
  ]);

  const onDismiss = () => {
    setOpenMobilePlayer(false);
    handleQueueListDisplay(false);
  };

  return (
    <div data-testid="player">
      <ReactPlayer
        ref={playerRef}
        url={url}
        height={0}
        className="hidden"
        playing={playing}
        onStart={handleStart}
        onEnded={handleEnded}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onError={handleError}
        playsinline
        playbackRate={speed}
        muted={sound === 0}
        config={{
          file: {
            forceAudio: true,
            hlsOptions: {
              forceHLS: true,
              startPosition: lastTimeInMedia,
              maxMaxBufferLength: 300,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              xhrSetup: function (xhr) {
                //xhr.withCredentials = true; // send cookies
                //xhr.setRequestHeader('Credential', '');
                //xhr.setRequestHeader('Authorization', session.accessToken);
              },
            },
          },
        }}
      />

      {openMobilePlayer ? (
        <BottomSheet
          open={openMobilePlayer}
          snapPoints={({ maxHeight }) => [maxHeight < 750 ? maxHeight - HEADER_HEIGHT : 600, 460]}
          onDismiss={onDismiss}
          ref={sheetRef}
          className="bottom-sheet"
          expandOnContentDrag={false}
        >
          <PlayerMobile />
        </BottomSheet>
      ) : (
        <>
          <SideBarRight isOpen={queueListDisplayed} setIsOpen={handleQueueListDisplay}>
            <QueueList />
          </SideBarRight>

          <div className="player-wrapper">
            <div className="flex justify-between items-center">
              <div
                className="flex grow"
                onClick={() => {
                  if (screenWidth < Breakpoints.Small) {
                    setOpenMobilePlayer(true);
                  }
                }}
              >
                <MediaInfo />
              </div>
              <PlayPause className="mx-6 md:hidden" />
            </div>
            <div className="max-md:player-mobile-timeline-controls md:player-timeline-controls">
              <div className="max-md:player-mobile-controls md:player-controls">
                <QueueListButton />
                <BackForward direction="backward" />
                <PlayPause />
                <BackForward direction="forward" />
                <Next />
              </div>

              <div className="max-md:player-mobile-timeline md:player-timeline">
                <ProgressBar />
                <div className="player-timeline-actions">
                  <Speed />
                  <Snooze />
                  <Sound />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const Player = memo(PlayerWrapper);
