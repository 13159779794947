import { DomainTypes } from './domain';
import { CorporateProgram, Program } from './program';

export type MediaInitializer = {
  source: DomainTypes;
  type?: 'media' | 'audio';
  domain: string;
  UUID: string;
  audioUUID: string;
  description: string;
  duration?: number;
  enabled: boolean;
  endPublicationDate: number;
  episode?: number;
  lang: string;
  programMediaKeyId?: string;
  programUUID?: string;
  playbackURL: string;
  publicationDate: number;
  status: MediaStatus;
  summary: string;
  syllabus: string[];
  title: string;
  transcriptURL?: string;
  uploadURL: string;
  verticalPictureURL: string;
  keyword1?: string;
  isFree?: boolean;
  trailer?: boolean;
  creationDate: number;
  modificationDate: number;
  deleted: boolean;
};

export interface Media extends MediaInitializer {
  mediaKeyId: string;
  isAvailable: boolean;
  isCorporate: boolean;
  isNew: boolean;
  isComingSoon: boolean;
  route: string;
  shareRoute: string;
  cover: string;
}

export type MediaHistory = {
  UUID: string;
  completionRate: number;
  duration?: number;
  episode?: number;
  isCompleted: boolean;
  isCompletedAndNotRestarted: boolean;
  lastCompletionDate: number | null;
  lastTimeInMedia: number;
  lastTimestamp: number;
  maxTimeInMedia: number;
  nbStreamByte: number;
  programUUID?: string;
  userUUID: string;
  markedAsRead?: boolean;
  isDeleted?: boolean;
};

export interface MediaInProgress {
  program: Program;
  media: Media;
  history: MediaHistory;
}

export interface MediaPlayBackUrl {
  media: Media;
  permanentPlaybackURL: string;
  source: string;
}

export enum MediaStatus {
  ComingSoon = 'comingSoon',
  Live = 'live',
  Run = 'run',
  New = 'new',
  NewEpisodes = 'newEpisodes',
  Unprocessed = 'unprocessed',
  Scheduled = 'scheduled',
  // Corporate
  Ready = 'ready',
  Error = 'error',
  Pending = 'pending',
  Processing = 'processing',
}

export enum PlayerStatus {
  Lock = 'lock',
  Start = 'start',
  Play = 'play',
  Pause = 'pause',
  Continue = 'continue',
}

export type CorporateMedia = {
  domain: string;
  type: 'media' | 'audio';
  uuid: string;
  createdAt: Date;
  description: string;
  duration: number;
  name: string;
  order: number;
  program: CorporateProgram;
  playbackURL: string;
  updatedAt: Date;
  uri: string;
  status: MediaStatus;
};

export enum QueuePosition {
  Top = 'top',
  Bottom = 'bottom',
}
