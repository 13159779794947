import { SSOTypes, UserInitializer } from '@/types';
import { Langs } from '@/types/lang';

export class UserEntity {
  UUID: string;
  avatarURL: string;
  birthdate: string;
  categories: string[];
  corpoJobCategory: string;
  corpoHomePageID: string;
  corpoDomainUUID?: string;
  createdAt: string;
  defaultPlaylistUUID: string;
  deleted: boolean;
  deletionComment: string;
  deletionDate: string;
  deletionReason: string;
  deviceID: string;
  email: string;
  emailApple: string;
  emailBO: string;
  /* @todo: remove emailFacebook when https://majelan.atlassian.net/browse/CONTENT-724 is completed */
  emailFacebook: string;
  emailGoogle: string;
  emailLink: string;
  enabled: boolean;
  expirationMd5: string;
  facebookID: string;
  firstName: string;
  gcu: boolean;
  hasBeenPremium: boolean;
  isTrial: boolean;
  lang: string;
  lastName: string;
  modifiedAt: string;
  newsletter: boolean;
  os: string;
  phoneNumber: string;
  plan: string;
  platform: string;
  premium: boolean;
  pushNotifications: boolean;
  refreshToken: string;
  role: string;
  signUpDate: string;
  signUpObjective: string;
  signUpPlatform: string;
  signUpType: SSOTypes;
  signUpUserAgent: string;
  stripeID: string;
  validated: boolean;
  validationMd5: string;
  isCorporate: boolean;

  constructor(user: UserInitializer) {
    this.UUID = user.UUID || '';
    this.avatarURL = user.avatarURL || '';
    this.birthdate = user.birthdate || '';
    this.categories = user.categories || [];
    this.corpoJobCategory = user.corpoJobCategory || '';
    this.corpoHomePageID = user.corpoHomePageID || '';
    this.corpoDomainUUID = user.corpoDomainUUID || '';
    this.createdAt = user.createdAt || '';
    this.defaultPlaylistUUID = user.defaultPlaylistUUID || '';
    this.deleted = user.deleted || false;
    this.deletionComment = user.deletionComment || '';
    this.deletionDate = user.deletionDate || '';
    this.deletionReason = user.deletionReason || '';
    this.deviceID = user.deviceID || '';
    this.email = user.email || '';
    this.emailApple = user.emailApple || '';
    this.emailBO = user.emailBO || '';
    this.emailFacebook = user.emailFacebook || '';
    this.emailGoogle = user.emailGoogle || '';
    this.emailLink = user.emailLink || '';
    this.enabled = user.enabled || false;
    this.expirationMd5 = user.expirationMd5 || '';
    this.facebookID = user.facebookID || '';
    this.firstName = user.firstName || '';
    this.gcu = user.gcu || false;
    this.hasBeenPremium = user.hasBeenPremium || false;
    this.isTrial = user.isTrial || false;
    this.lang = user.lang || Langs.Fr;
    this.lastName = user.lastName || '';
    this.modifiedAt = user.modifiedAt || '';
    this.newsletter = user.newsletter || false;
    this.os = user.os || '';
    this.phoneNumber = user.phoneNumber || '';
    this.plan = user.plan || '';
    this.platform = user.platform || '';
    this.premium = user?.premium || user.signUpType === SSOTypes.Corporate;
    this.pushNotifications = user.pushNotifications || false;
    this.refreshToken = user.refreshToken || '';
    this.role = user.role || '';
    this.signUpDate = user.signUpDate || '';
    this.signUpObjective = user.signUpObjective || '';
    this.signUpPlatform = user.signUpPlatform || '';
    this.signUpType = user.signUpType;
    this.signUpUserAgent = user.signUpUserAgent || '';
    this.stripeID = user.stripeID || '';
    this.validated = user.validated || false;
    this.validationMd5 = user.validationMd5 || '';
    this.isCorporate = this.signUpType === SSOTypes.Corporate || !!user.corpoHomePageID;
  }
}
