import { getUnixTime } from 'date-fns';

import { CorporateMedia, CorporateProgram, DomainTypes, Media, MediaStatus } from '@/types';
import { Langs } from '@/types/lang';

import { MediaEntity } from './MediaEntity';

export class CorporateMediaEntity {
  domain: string;
  type: 'media' | 'audio';
  UUID: string;
  createdAt: Date;
  description: string;
  duration: number;
  name: string;
  order: number;
  program: CorporateProgram;
  playbackURL: string;
  updatedAt: Date;
  uri: string;
  status: MediaStatus;

  constructor(corporateMedia: CorporateMedia) {
    this.domain = corporateMedia.domain;
    this.type = corporateMedia.type;
    this.UUID = corporateMedia.uuid;
    this.createdAt = new Date(corporateMedia.createdAt);
    this.description = corporateMedia.description;
    this.duration = corporateMedia.duration;
    this.name = corporateMedia.name;
    this.order = corporateMedia.order;
    this.program = corporateMedia.program;
    this.playbackURL = corporateMedia.playbackURL;
    this.updatedAt = new Date(corporateMedia.updatedAt);
    this.uri = corporateMedia.uri;
    this.status = corporateMedia.status;
  }

  toMedia(): Media {
    return new MediaEntity({
      source: DomainTypes.Corporate,
      type: this.type,
      domain: this.domain,
      UUID: this.UUID,
      audioUUID: this.UUID,
      programUUID: this.program?.uuid,
      playbackURL: this.uri,
      description: this.description,
      verticalPictureURL: this.program?.coverPortrait || '',
      summary: '',
      syllabus: [],
      creationDate: getUnixTime(this.createdAt),
      deleted: false,
      duration: this.duration,
      enabled: true,
      endPublicationDate: null,
      episode: this.order,
      isFree: true,
      lang: Langs.Fr,
      modificationDate: getUnixTime(this.updatedAt),
      publicationDate: getUnixTime(this.createdAt),
      status: this.status || MediaStatus.Live,
      title: this.name,
      trailer: false,
      transcriptURL: '',
      uploadURL: '',
    });
  }
}
